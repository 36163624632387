import React from 'react';
import styled from 'styled-components';
import { FormGroup, Label, FormMessage } from '@admin-bro/design-system';

import FormInput from './form-input';

const StyledMessage = styled(FormMessage)`
  height: auto;
  min-height: 10px;
`;

const LABELS = {
  subscriptionCarrierId: {
    label: 'Subscription ID',
    placeholder: '',
    required: true,
  },
  imei: {
    label: 'IMEI Number',
    placeholder: '',
    required: true,
  },
};

export type AvailableFields =
  | 'subscriptionCarrierId'
  | 'imei';
export type SignUpFormGroupProps = {
  label: string;
  field: AvailableFields;
  onChange: (property: any, value: any) => void;
  value: any;
  errorMessage?: string;
};

const InputFormGroup: React.FC<SignUpFormGroupProps> = (props) => {
  const {
    label, field, value, onChange, errorMessage,
  } = props;
  return (
    <FormGroup error={!!errorMessage}>
      <Label required={LABELS[field].required} htmlFor={field} color="deepCove">
        {label || LABELS[field].label}
      </Label>
      <FormInput
        name={field}
        id={field}
        placeholder={LABELS[field].placeholder}
        value={value}
        onChange={(event): void => onChange(field, event.target.value)}
      />
      <StyledMessage>{errorMessage}</StyledMessage>
    </FormGroup>
  );
};

export default InputFormGroup;
