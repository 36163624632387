module.exports = {
  companyName: {
    presence: {
      message: 'You need to give Company Name',
      allowEmpty: false,
    },
    length: {
      minimum: 6,
    },
    type: 'string',
  },
  email: {
    email: true,
    length: {
      minimum: 6,
    },
  },
  firstName: {
    presence: {
      message: 'You need to give your first name',
      allowEmpty: false,
    },
    type: 'string',
  },
  lastName: {
    type: 'string',
  },
  storeUrl: {
    type: 'string',
  },
  country: {
    presence: {
      message: 'You must select your country',
      allowEmpty: false,
    },
    type: 'string',
  },
  password: {
    presence: {
      message: 'You need to give your password',
      allowEmpty: false,
    },
    length: {
      minimum: 6,
      tooShort: 'Password needs to be at least %{count} long',
    },
    type: 'string',
  },
};
