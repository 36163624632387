import React, { useState } from 'react';
import {
  FormGroup, Label, Button, Icon, InputGroup,
} from '@admin-bro/design-system';

import branding from '../../config/branding';

import { StyledMessage } from './signup-form';
import FormInput from './form-input';

export type PasswordFormGroupProps = {
  onChange: (property: any, value: string) => void;
  value: string;
  errorMessage?: string;
}

const PasswordFormGroup: React.FC<PasswordFormGroupProps> = (props) => {
  const [isVisible, setIsVisible] = useState(false);

  const {
    value, onChange, errorMessage,
  } = props;
  return (
    <FormGroup error={!!errorMessage}>
      <Label required htmlFor="password" color="deepCove">Password</Label>
      <InputGroup>
        {isVisible ? (
          <FormInput
            id="password"
            name="password"
            placeholder="*********"
            value={value}
            onChange={(event): void => onChange('password', event.target.value)}
          />
        ) : (
          <FormInput
            name="password"
            id="password"
            placeholder="*********"
            type="password"
            value={value}
            onChange={(event): void => onChange('password', event.target.value)}
          />
        )}
        <Button
          variant={isVisible ? 'primary' : 'text'}
          type="button"
          size="icon"
          onClick={(): void => setIsVisible(!isVisible)}
          style={{ background: branding.theme.colors.athensGrey, border: 'none', outline: 'none' }}
        >
          <Icon icon="View" />
        </Button>
      </InputGroup>
      <StyledMessage>{errorMessage}</StyledMessage>
    </FormGroup>
  );
};

export default PasswordFormGroup;
