import React from 'react';
import { H2 } from '@admin-bro/design-system';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const logo = require('../frontend/bolttech-logo-25h.png');

const Logo: React.FC<{}> = () => (
  <H2 my="lg" pt="xs" mb="x3">
    <img src={logo} alt="bolttech" />
  </H2>
);

export default Logo;
