import React from 'react';
import { Box, Text } from '@admin-bro/design-system';
import styled from 'styled-components';

import branding from '../../config/branding';

const Step = styled(Box)`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
`;

const CheckpointBox = styled(Box)`
  position: relative;
  padding-bottom: 20px;
  z-index: 1;
  box-sizing: border-box;

  &:not(:last-child):before {
    content: '';
    position: relative;
    left: 16px;
    top: 32px;
    border-left: 1px solid ${branding.theme.colors.corn};
    z-index: -1;
  }
`;

type Props = {
  title: string;
  subtitle: string;
  stepNumber: number;
}

const CheckPoint: React.FC<Props> = (props) => {
  const { title, subtitle, stepNumber } = props;

  return (
    <CheckpointBox flex>
      <Box mr="default" mt="sm" flexShrink={0}>
        <Step color="primary100" bg="corn">{stepNumber}</Step>
      </Box>
      <Box>
        <Text fontWeight="bold" mt="default" color="primary100">{title}</Text>
        <Text fontWeight="lighter" mt="sm">{subtitle}</Text>
      </Box>
    </CheckpointBox>
  );
};

export default CheckPoint;
