/* eslint-disable max-len */
import React, { useState, useCallback, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import {
  Box, FormMessage, Button, Text, Icon, Loader,
} from '@admin-bro/design-system';

import RegisterImeiSchema from '../schemas/register-imei.schema.js';
import validate from '../../utils/validate';

import InputFormGroup from './input-form-group';

export type ResetPasswordFormData = {
  imei?: string;
}

const emptyForm: ResetPasswordFormData = {
  imei: '',
};

type Properties = keyof ResetPasswordFormData;

type Errors = {
  [key: string]: {
    message: string;
  } | undefined;
}

export const StyledMessage = styled(FormMessage)`
  height: auto;
  min-height: 10px;
`;

const RegisterImeiForm: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [errors, setErrors] = useState<Errors>({});
  const [formData, setFormData] = useState(emptyForm);

  const [introMessage, setIntroMessage] = useState('');
  const [fieldLabel, setFieldLabel] = useState('');
  const [imeiExists, setImeiExists] = useState(false);

  const serverURL = process.env.SERVER_URL;

  const urlParams = new URLSearchParams(window.location.search);
  // const customerCarrierId = urlParams.get('ccid');
  // const subscriptionCarrierId = urlParams.get('scid');

  const customerId = urlParams.get('cid');
  const subscriptionId = urlParams.get('sid');

  const trackingSrc = urlParams.get('src');

  // check if Customer ID and Subscription ID are supplied and valid
  useEffect(() => {
    if (!customerId || !subscriptionId) {
      setErrors({ urlVerified: { message: 'URL or link is malformed or invalid. Customer ID and/or Subscription ID not found' } });
    } else {
      const apiUrl = `${serverURL}/api/subscriptions/validateById/${customerId}/${subscriptionId}`;
      axios({
        method: 'get',
        url: apiUrl,
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((response) => {
          const productType = response.data.productCovered?.type ? response.data.productCovered.type : '';
          if (response.data.imei) {
            setImeiExists(true);
          }

          // Handsets require IMEI - all other categories require Serial number
          if (productType === 'Handset') {
            setIntroMessage('Enter your IMEI number below to register with your policy. To get your IMEI, simply dial *#06# on your device');
            setFieldLabel('IMEI Number');
          } else {
            setIntroMessage('Please provide the IMEI or serial number of your device');
            setFieldLabel('IMEI or Serial Number');
          }

          setIsLoading(false);
          setLoading(false);
          return true;
        })
        .catch(() => {
          setIsLoading(false);
          setLoading(false);
          setErrors({ urlVerified: { message: 'Customer ID and/or Subscription ID provided are not valid' } });
          return false;
        });
    }
  }, [setErrors, customerId, subscriptionId, serverURL]);

  const onChange = useCallback((param: Properties, value) => {
    setFormData({ ...formData, [param]: value });
  }, [formData]);

  const onSubmit = useCallback((event) => {
    event.preventDefault();

    setLoading(true);

    const validationErrors = validate(formData, RegisterImeiSchema);
    if (validationErrors) { setLoading(false); return setErrors(validationErrors); }

    // Update the subscription
    axios({
      method: 'patch',
      url: `${serverURL}/api/subscriptions/update/imei/${customerId}/${subscriptionId}`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        imei: formData.imei,
        src: trackingSrc || '',
      },
    })
      .then(() => {
        setLoading(false);
        window.location.href = '?mode=imei-registered';
        return true;
      })
      .catch(() => {
        setErrors({ subscriptionCarrierId: { message: 'Subscription ID not found' } });
        setLoading(false);
        return false;
      });

    // const data = new FormData();
    // Object.entries(formData).forEach(([key, val]) => data.set(key, val));

    return false;
  }, [formData, serverURL, customerId, subscriptionId, trackingSrc]);

  return (
    <Box
      bg="white"
      width={['100%', '100%', '480px']}
      py="xxl"
      px="xxl"
      mt="sm"
      overflow="auto"
    >

      { window.location.href.includes('mode=imei-registered') ? (
        <Box
          bg="grannyApple"
          flex
          flexDirection="column"
          justifyContent="center"
          p="lg"
          mb="lg"
        >
          <Text color="primary100" fontSize="14px" lineHeight="18px">IMEI Registered Successfully!</Text>
        </Box>
      ) : (
        <form onSubmit={onSubmit}>
          {errors && errors.urlVerified ? (
            <Box
              bg="remy"
              flex
              flexDirection="column"
              justifyContent="center"
              p="lg"
              mb="lg"
            >
              <Text color="primary100" fontSize="14px" lineHeight="18px">{errors.urlVerified.message}</Text>
            </Box>
          ) :
            (
              <>
                { isLoading ? <Loader /> : (
                  <>
                    {imeiExists ? (
                      <Box
                        bg="grannyApple"
                        flex
                        flexDirection="column"
                        justifyContent="center"
                        p="lg"
                        mb="lg"
                      >
                        <Text color="primary100" fontSize="14px" lineHeight="18px">{fieldLabel} for this policy has already been registered. Please contact bolttech to update this number</Text>
                      </Box>

                    ) : (

                      <div>
                        <Box pb="xl">
                          <Text color="grey60" fontSize="14">{introMessage}</Text>
                        </Box>

                        <InputFormGroup
                          label={fieldLabel}
                          key="imei"
                          field="imei"
                          value={formData.imei}
                          errorMessage={errors.imei?.message}
                          onChange={onChange}
                        />

                        <Text textAlign="center">
                          <Button variant="primary" type="submit" style={{ borderRadius: '20px' }}>
                            {loading ? (<Icon icon="Fade" spin />) : null}
                            Save
                          </Button>
                        </Text>
                      </div>

                    )}

                  </>
                )}
              </>
            )}

        </form>
      )}

    </Box>
  );
};

export default RegisterImeiForm;
