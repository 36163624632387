import React from 'react';
import {
  Text, Box, Button, Link,
} from '@admin-bro/design-system';

const emailVerifiedText = 'Your email has been verified. You may now login.';

const EmailVerified: React.FC = () => (
  <Box
    bg="white"
    width={['100%', '100%', '480px']}
    py="xxl"
    pl="xxl"
    pr="xxl"
    mt="sm"
    overflow="auto"
    mx="auto"
  >
    <Text textAlign="center" color="grey80">{emailVerifiedText}</Text>
    <Box pt="lg" flex flexDirection="row" justifyContent="center" alignItems="center">
      <Link textAlign="center" href="/app/login">
        <Button variant="primary" style={{ borderRadius: '20px' }}>
          Login
        </Button>
      </Link>
    </Box>
  </Box>
);

export default EmailVerified;
