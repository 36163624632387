/* eslint-disable consistent-return */
/* eslint-disable max-len */
import React, { useState, useCallback, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import {
  Box, H2, FormMessage, Button, Text, Icon, Link,
} from '@admin-bro/design-system';

import RegisterSchema from '../schemas/register.schema.js';
import validate from '../../utils/validate';

import SignUpFormGroup, { AvailableFields } from './signup-form-group';
import PasswordFormGroup from './password-form-group';
import SelectFormGroup from './select-form-group';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const logo = require('../frontend/bolttech-logo-25h.png');

export type SignUpFormData = {
  email?: string;
  firstName?: string;
  lastName?: string;
  companyName?: string;
  storeUrl?: string;
  password?: string;
  country?: any;
  captchaToken?: string;
}

const emptyForm: SignUpFormData = {
  email: '',
  firstName: '',
  lastName: '',
  companyName: '',
  storeUrl: '',
  password: '',
  country: '',
};

type Properties = keyof SignUpFormData

type Errors = {
  [key: string]: {
    message: string;
  } | undefined;
}

export const StyledMessage = styled(FormMessage)`
  height: auto;
  min-height: 10px;
`;

const SignUpForm: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(emptyForm);
  const [errors, setErrors] = useState<Errors>({});

  const onChange = useCallback((param: Properties, value) => {
    setFormData({ ...formData, [param]: value });
  }, [formData]);

  useEffect(() => {
    const loadScriptByURL = (id, url, callback) => {
      const isScriptExist = document.getElementById(id);

      if (!isScriptExist) {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = url;
        script.id = id;
        script.onload = function () {
          if (callback) callback();
        };
        document.body.appendChild(script);
      }

      if (isScriptExist && callback) callback();
    };

    // load the script by passing the URL
    loadScriptByURL('recaptcha-key', 'https://www.google.com/recaptcha/api.js?render=6Lc48Q0cAAAAAMMlhYWhedkxpEw-3UrjzqErkENT', () => {
      // console.log('Script loaded!');
    });
  }, []);

  const onSubmit = useCallback((event) => {
    event.preventDefault();

    window.grecaptcha.ready(() => {
      window.grecaptcha.execute('6Lc48Q0cAAAAAMMlhYWhedkxpEw-3UrjzqErkENT', { action: 'submit' }).then((token) => {
        setLoading(true);

        formData.country = formData.country.value;
        formData.captchaToken = token;

        const validationErrors = validate(formData, RegisterSchema);
        if (validationErrors) { return setErrors(validationErrors); }

        const data = new FormData();
        Object.entries(formData).forEach(([key, val]) => data.set(key, val));

        axios.post('/app/signup', data).then((response) => {
          window.location = response.data.redirectUrl;
        }).catch((error) => {
          if (error.response.status === 409) {
            setErrors(error.response.data.errors);
          } else {
            // eslint-disable-next-line no-console
            console.log({ error });
          }
          setLoading(false);
        });
      });
    });

    return false;
  }, [formData]);

  return (
    <Box
      bg="white"
      width={['100%', '100%', '480px']}
      py="xxl"
      pl="xxl"
      pr="x4"
      mt="sm"
      overflow="auto"
    >
      <H2 my="xl" pt="xs" mb="x3">
        <img src={logo} alt="bolttech" />
      </H2>
      <form onSubmit={onSubmit}>
        {['email', 'firstName', 'lastName', 'companyName', 'storeUrl'].map((field: AvailableFields) => (
          <SignUpFormGroup
            key={field}
            field={field}
            value={formData[field]}
            errorMessage={errors[field]?.message}
            onChange={onChange}
          />
        ))}

        <SelectFormGroup
          value={formData.country}
          errorMessage={errors.country?.message}
          onChange={onChange}
        />

        <PasswordFormGroup
          value={formData.password}
          errorMessage={errors.password?.message}
          onChange={onChange}
        />
        <Text textAlign="center">
          <Button variant="primary" type="submit">
            {loading ? (<Icon icon="Fade" spin />) : null}
            Register
          </Button>
          <Box py="lg">
            <Link href="/app/login">Already registered? Sign in</Link>
          </Box>
        </Text>
      </form>
    </Box>
  );
};

export default SignUpForm;
