import React from 'react';
import { Box, H2 } from '@admin-bro/design-system';

import branding from '../../config/branding';

import CheckPoint from './checkpoint';

const RegisterInfo: React.FC = () => (
  <Box
    bg="eggBlue"
    color="white"
    p="x3"
    width="384px"
    flexGrow={0}
    height="100%"
    display={['none', 'none', 'block']}
    position="relative"
  >
    <H2 mb="x3" fontWeight="bold" style={{ color: branding.theme.colors.primary100 }}>Register</H2>
    <CheckPoint
      title="Sign Up for Free"
      subtitle="Enter your details to create an account."
      stepNumber={1}
    />
    <CheckPoint
      title="Activate your Account"
      subtitle="This usually takes less than 1-2 business days to complete."
      stepNumber={2}
    />
    <CheckPoint
      title="Integrate bolttech to your Store"
      subtitle="Access to our eCommerce plugins or REST API for integration."
      stepNumber={3}
    />
    <CheckPoint
      title="Earn Money by Offering Warranties"
      subtitle="Start earning money by placing bolttech products with your site."
      stepNumber={4}
    />
  </Box>
);

export default RegisterInfo;
