import React from 'react';
import {
  Text, Box, Button, Link,
} from '@admin-bro/design-system';

const passwordResetConfirmationText = 'Your password has been reset. Please check your email.';

const ResetPasswordConfirmation: React.FC = () => (
  <Box
    bg="white"
    width={['100%', '100%', '480px']}
    py="xxl"
    px="xxl"
    mt="sm"
    overflow="auto"
  >
    <Text textAlign="center" color="grey80">{passwordResetConfirmationText}</Text>
    <Box pt="lg">
      <Link textAlign="center" href="/app/login">
        <Button variant="primary">
          Return
        </Button>
      </Link>
    </Box>
  </Box>
);

export default ResetPasswordConfirmation;
