import {
  Input,
} from '@admin-bro/design-system';
import styled from 'styled-components';

import branding from '../../config/branding';

const FormInput = styled(Input)`
  background: ${branding.theme.colors.athensGrey};
  border: none;
  color: ${branding.theme.colors.deepCove};
`;

export default FormInput;
