import React from 'react';
import styled from 'styled-components';
import { FormGroup, Label, FormMessage } from '@admin-bro/design-system';

import FormInput from './form-input';

const StyledMessage = styled(FormMessage)`
  height: auto;
  min-height: 10px;
`;

const LABELS = {
  email: {
    label: 'Email address',
    placeholder: 'your@email.com',
    required: true,
  },
  loginEmail: {
    label: 'Login',
    placeholder: 'your@email.com',
    required: true,
  },
  resetEmail: {
    label: 'Email',
    placeholder: 'your@email.com',
    required: true,
  },
  resendEmail: {
    label: 'Email',
    placeholder: 'your@email.com',
    required: true,
  },
  firstName: {
    label: 'First name',
    placeholder: 'Joe',
    required: true,
  },
  lastName: {
    label: 'Last name',
    placeholder: 'Bloggs',
    required: false,
  },
  companyName: {
    label: 'Company Name',
    placeholder: 'Joe Co.',
    required: true,
  },
  storeUrl: {
    label: 'Store URL',
    placeholder: 'http://www.mystore.com',
    required: true,
  },
};

export type AvailableFields =
  | 'email'
  | 'loginEmail'
  | 'resetEmail'
  | 'resendEmail'
  | 'firstName'
  | 'companyName'
  | 'storeUrl';

export type SignUpFormGroupProps = {
  field: AvailableFields;
  onChange: (property: any, value: any) => void;
  value: any;
  errorMessage?: string;
};

const SignUpFormGroup: React.FC<SignUpFormGroupProps> = (props) => {
  const {
    field, value, onChange, errorMessage,
  } = props;
  return (
    <FormGroup error={!!errorMessage}>
      <Label required={LABELS[field].required} htmlFor={field} color="deepCove">
        {LABELS[field].label}
      </Label>
      <FormInput
        name={field}
        id={field}
        placeholder={LABELS[field].placeholder}
        value={value}
        onChange={(event): void => onChange(field, event.target.value)}
      />
      <StyledMessage>{errorMessage}</StyledMessage>
    </FormGroup>
  );
};

export default SignUpFormGroup;
