import React from 'react';

import RegisterInfo from '../components/register-info';
import SignUpForm from '../components/signup-form';

const Register: React.FC = () => (
  <>
    <RegisterInfo />
    <SignUpForm />
  </>
);

export default Register;
