module.exports = {
  loginEmail: {
    presence: {
      message: 'You need to give your email',
      allowEmpty: false,
    },
    email: {
      message: 'You must provide a valid email',
    },
  },
  password: {
    presence: {
      message: 'You need to give your password',
      allowEmpty: false,
    },
    type: 'string',
  },
};
