import {
  Box,
  Button,
  FormMessage,
  Icon,
  Link,
  Text,
} from '@admin-bro/design-system';
import React, { useCallback, useState } from 'react';

import ResendEmailSchema from '../schemas/resend-verification-email.schema.js';
import SignUpFormGroup from './signup-form-group';
import axios from 'axios';
import styled from 'styled-components';
import validate from '../../utils/validate';

export type ResendEmailFormData = {
  resendEmail?: string;
}

const emptyForm: ResendEmailFormData = {
  resendEmail: '',
};

type Properties = keyof ResendEmailFormData;

type Errors = {
  [key: string]: {
    message: string;
  } | undefined;
}

export const StyledMessage = styled(FormMessage)`
  height: auto;
  min-height: 10px;
`;

const resendEmailTip = [
  'Enter the email you used when registering',
  'and we\'ll send you a new activation link.',
];

const ResendEmailForm: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(emptyForm);
  const [errors, setErrors] = useState<Errors>({});

  const onChange = useCallback((param: Properties, value) => {
    setFormData({ ...formData, [param]: value });
  }, [formData]);

  const onSubmit = useCallback((event) => {
    event.preventDefault();

    const validationErrors = validate(formData, ResendEmailSchema);
    if (validationErrors) { return setErrors(validationErrors); }

    const data = new FormData();
    Object.entries(formData).forEach(([key, val]) => data.set(key, val));

    setLoading(true);
    axios.post('/app/resend-email', data).then((response) => {
      window.location = response.data.redirectUrl;
    }).catch((error) => {
      if (error.response.status === 401 || error.response.status === 404 || error.response.status === 400) {
        setErrors(error.response.data.errors);
      } else {
        // eslint-disable-next-line no-console
        console.log({ error });
      }
      setLoading(false);
    });
    return false;
  }, [formData]);

  return (
    <Box
      bg="white"
      width={['100%', '100%', '480px']}
      py="xxl"
      px="xxl"
      mt="sm"
      overflow="auto"
    >
      <Box pb="xl">
        {resendEmailTip
          .map((tipLine) => <Text textAlign="center" color="grey60" fontSize="13">{tipLine}</Text>)}
      </Box>
      <form onSubmit={onSubmit}>
        <SignUpFormGroup
          key="resendEmail"
          field="resendEmail"
          value={formData.resendEmail}
          errorMessage={errors.resendEmail?.message}
          onChange={onChange}
        />
        <Text textAlign="center">
          <Button variant="primary" type="submit" style={{ borderRadius: '20px' }}>
            {loading ? (<Icon icon="Fade" spin />) : null}
            Continue
          </Button>
        </Text>
      </form>
      <Box py="lg">
        <Link href="/app/login">Return to login</Link>
      </Box>
    </Box>
  );
};

export default ResendEmailForm;
