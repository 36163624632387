module.exports = {
  imei: {
    presence: {
      message: 'Please provide a valid IMEI',
      allowEmpty: false,
    },
    length: {
      is: 15,
      message: 'IMEI must be 15 characters in length',
    },
  },
};
