module.exports = {
  logo: '/public/bolttech-logo.png',
  companyName: 'bolttech Merchant Portal',
  favicon: '/public/favicon.png',
  theme: {
    colors: {
      primary100: '#170F4F',
      accent: '#38CAF1',
      inputBorder: '#F3F3F6',
      filterBg: '#FFF',
      // chir.ag/projects/name-that-color
      dustyGrey: '#979797',
      eggBlue: '#02BAC7',
      white: '#FFFFFF',
      corn: '#E3D801',
      comet: '#5A5483',
      athensGrey: '#F3F3F6',
      deepCove: '#0F064D',
      grannyApple: '#E9FAEE',
      remy: '#FEEDF3',
      froly: '#F0616F',
      frenchGrey: '#C4C4CC',
      silverTree: '#5ABE99',
      radicalRed: '#FF4567',
      iceberg: '#D4F0F4',
      whiteLilac: '#F6F7FB',
      hawkesBlue: '#CBD5FD',
      scarpaFlow: '#535369',
      manatee: '#898A9A',
      alabaster: '#FAFAFA',
      swansDown: '#DBF0F1',
    },
  },
};
