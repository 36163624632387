/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import ReactDOM from 'react-dom';
import styled, { ThemeProvider, createGlobalStyle } from 'styled-components';
import { combineStyles, Box } from '@admin-bro/design-system';

import branding from '../../config/branding';
import Logo from '../components/logo';
import DontHaveAnAccountTip from '../components/dont-have-account-tip';

import Register from './register';
import Login from './login';
import RemindPassword from './remind-password';
import ResetPassword from './reset-password';
import ResetPasswordConfirmation from './imer';
import EmailVerified from './email-verified';
import ResendEmail from './resend-email';
import ImeiRegistration from './imei-registration';

const theme = combineStyles(branding.theme);

const GlobalStyle = createGlobalStyle`
  html, body, #app {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden;
  }
`;

const Wrapper = styled(Box)`
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
`;

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const buildApp = (
  Component: React.ComponentClass | React.FC,
  HeaderComponent: React.FC | null = null,
  FooterComponent: React.FC | null = null,
) => (
  <ThemeProvider theme={theme}>
    <GlobalStyle />
    <Wrapper flex bg="grey20">
      {HeaderComponent && <HeaderComponent />}
      <Box
        bg="white"
        maxHeight="100%"
        flex
        height={['100%', 'auto']}
        boxShadow="login"
        width={[1, 2 / 3, 'auto']}
      >
        <Component />
      </Box>
      {FooterComponent && <FooterComponent />}
    </Wrapper>
  </ThemeProvider>
);

const register = document.getElementById('register');
if (register) {
  ReactDOM.render(buildApp(Register), register);
}

const login = document.getElementById('login');
if (login) {
  ReactDOM.render(buildApp(Login, Logo, DontHaveAnAccountTip), login);
}

const remindPassword = document.getElementById('remindPassword');
if (remindPassword) {
  ReactDOM.render(buildApp(RemindPassword), remindPassword);
}

const resetPassword = document.getElementById('resetPassword');
if (resetPassword) {
  ReactDOM.render(
    buildApp(ResetPassword, Logo, DontHaveAnAccountTip),
    resetPassword,
  );
}

const imeiRegistration = document.getElementById('imeiRegistrationWrapper');
if (imeiRegistration) {
  ReactDOM.render(buildApp(ImeiRegistration, Logo), imeiRegistration);
}

const resetPasswordConfirmation = document.getElementById(
  'resetPasswordConfirmation',
);
if (resetPasswordConfirmation) {
  ReactDOM.render(
    buildApp(ResetPasswordConfirmation, Logo),
    resetPasswordConfirmation,
  );
}

const emailVerified = document.getElementById('emailVerified');
if (emailVerified) {
  ReactDOM.render(buildApp(EmailVerified, Logo), emailVerified);
}

const resendEmail = document.getElementById('resendEmail');
if (resendEmail) {
  ReactDOM.render(buildApp(ResendEmail, Logo), resendEmail);
}
