import React from 'react';
import axios from 'axios';
import fetch from 'node-fetch';
import {
  FormGroup, Label,
} from '@admin-bro/design-system';
// import Select from 'react-select';
import AsyncSelect from 'react-select/async';

import { StyledMessage } from './signup-form';

type SelectOption = {
  value: string;
  label: string;
};

export type SelectFormGroupProps = {
  onChange: (property: any, value: object) => void;
  value: string;
  errorMessage?: string;
}

function capitalize(word) {
  return word
    .split('')
    .map((letter, index) =>
      (index ? letter.toLowerCase() : letter.toUpperCase()))
    .join('');
}

const serverURL = process.env.SERVER_URL;

const getCountries = async (inputValue: string) => {
  const countries = await axios.get(`${serverURL}/api/merchants/countries`).then((response) => {
    const availCountries = response.data.map((country) => ({ value: country.code, label: capitalize(country.name) }));

    const availCountries2 = response.data.map((country) => {
      if (country.name.includes(inputValue.toLowerCase())) {
        return { value: country.code, label: capitalize(country.name) };
      }
      return null;
    });

    if (inputValue) {
      return availCountries2.filter((x) => x !== null);
    }
    return availCountries;
  }).catch((error) => false);
  return countries;
};

const promiseOptions = (inputValue) =>
  new Promise((resolve) => {
    setTimeout(() => {
      resolve(getCountries(inputValue));
    }, 1000);
  });

const SelectFormGroup: React.FC<SelectFormGroupProps> = (props) => {
  const {
    value, onChange, errorMessage,
  } = props;

  return (<>
    <FormGroup error={!!errorMessage}>
      <Label required htmlFor="password" color="deepCove">Country</Label>
      <AsyncSelect
        name="country"
        id="country"
        value={value || null}
        styles={selectStyles}
        cacheOptions
        defaultOptions
        loadOptions={promiseOptions}
        onChange={(v: SelectOption): void => onChange('country', v)}
      />
      <StyledMessage>{errorMessage}</StyledMessage>
    </FormGroup>
    </>
  );
};

const selectStyles = {
  control: () => ({
    background: '#F3F3F6',
    display: 'flex',
  }),
};

export default SelectFormGroup;
