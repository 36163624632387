const validate = require('validate.js');
const flat = require('flat');

/**
 * Validates params according to one schema
 * @argument {Record<String, any>}         params
 * @argument {Schema}                      schema
 * @return {Record<String, ErrorMessage>}
 */
const validateSingleSchema = (params, schema) => {
  const errors = validate(params, schema, { fullMessages: false });

  if (!errors) {
    return {};
  }

  return Object.entries(errors).reduce((memo, [key, value]) => ({
    ...memo,
    [key]: {
      message: value.join(', '),
    },
  }), {});
};

/**
 * Validates params according to single or multiple schemas
 *
 * @argument {Record<String, String>}      params
 * @argument {Schema | Array<Schema>}   schemas
 * @return {Record<String, ErrorMessage> | null}
 */
const validateAll = (params, schemas) => {
  // validate.js validator doesn't accept parm names with dots, but
  // it accepts nested objects. That is why we have to unflatten params.
  const unflattenParams = flat.unflatten(params);
  const schemasToValidate = schemas instanceof Array ? schemas : [schemas];

  const allErrors = schemasToValidate.reduce((memo, schema) => {
    const errors = validateSingleSchema(unflattenParams, schema);

    return {
      ...memo,
      ...errors,
    };
  }, {});

  return Object.keys(allErrors).length ? allErrors : null;
};

module.exports = validateAll;
