import {
  Box,
  Button,
  FormMessage,
  Icon,
  Link,
  Text,
} from '@admin-bro/design-system';
import React, { useCallback, useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';

import LoginSchema from '../schemas/login.schema.js';
import validate from '../../utils/validate';

import PasswordFormGroup from './password-form-group';
import SignUpFormGroup from './signup-form-group';

const passwordResetTip = 'Your request to reset password was successful. Please check your email for verification.';
// eslint-disable-next-line max-len
const emailVerificationTip = 'Your account was created. We have sent you an email with a link to activate your account.';
const emailResentTip = 'Your verification email has been resent.';
const codeExpiredTip = 'The link you\'re trying to redeem has already expired.';

export type LoginFormData = {
  loginEmail?: string;
  password?: string;
}

const emptyForm: LoginFormData = {
  loginEmail: '',
  password: '',
};

type Properties = keyof LoginFormData

type Errors = {
  [key: string]: {
    message: string;
  } | undefined;
}

export const StyledMessage = styled(FormMessage)`
  height: auto;
  min-height: 10px;
`;

const LoginForm: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(emptyForm);
  const [errors, setErrors] = useState<Errors>({});

  const onChange = useCallback((param: Properties, value) => {
    setFormData({ ...formData, [param]: value });
  }, [formData]);

  const onSubmit = useCallback((event) => {
    event.preventDefault();

    const validationErrors = validate(formData, LoginSchema);
    if (validationErrors) { return setErrors(validationErrors); }

    const data = new FormData();
    Object.entries(formData).forEach(([key, val]) => data.set(key, val));
    setLoading(true);
    axios.post('/app/login', data).then((response) => {
      localStorage.setItem('refresh_token', response.data.keycloack);
      window.location = response.data.redirectUrl;
    }).catch((error) => {
      if (error.response.status === 401 || error.response.status === 404 || error.response.status === 400) {
        setErrors(error.response.data.errors);
      } else {
        // eslint-disable-next-line no-console
        console.log({ error });
      }
      setLoading(false);
    });
    return false;
  }, [formData]);

  return (
    <Box
      bg="white"
      width={['100%', '100%', '480px']}
      py="xxl"
      px="xxl"
      mt="sm"
      overflow="auto"
      mx="auto"
    >
      { window.location.href.includes('mode=password-reset') && <Text color="green" mb="lg">{passwordResetTip}</Text>}
      { window.location.href.includes('mode=email-resent') && <Text color="green" mb="lg">{emailResentTip}</Text>}
      { window.location.href.includes('mode=code-expired') && <Text color="red" mb="lg">{codeExpiredTip}</Text>}
      { window.location.href.includes('mode=email-verification')
      && (
      <Text
        color="green"
        mb="lg"
      >
        {emailVerificationTip}
      </Text>
      )}
      {errors && errors.emailVerified ? (
        <Box
          bg="hawkesBlue"
          flex
          flexDirection="column"
          justifyContent="center"
          p="lg"
          mb="lg"
        >
          <Text color="scarpaFlow" fontSize="14px" lineHeight="16px" mb="lg">{errors.emailVerified.message}</Text>
          <Link href="/app/resend-email" color="eggBlue">Resend verification email</Link>
        </Box>
      ) : null}
      <form onSubmit={onSubmit}>
        <SignUpFormGroup
          key="email"
          field="loginEmail"
          value={formData.loginEmail}
          errorMessage={errors.loginEmail?.message}
          onChange={onChange}
        />
        <PasswordFormGroup
          value={formData.password}
          errorMessage={errors.password?.message}
          onChange={onChange}
        />
        <Box my="lg" pb="sm">
          <Link href="/app/reset-password">Forgot password?</Link>
        </Box>
        <Text textAlign="center">
          <Button variant="primary" type="submit" style={{ borderRadius: '20px' }}>
            {loading ? (<Icon icon="Fade" spin />) : null}
            Login stage portal
          </Button>
        </Text>
      </form>
    </Box>
  );
};

export default LoginForm;
