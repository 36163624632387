import React from 'react';
import { Link } from '@admin-bro/design-system';

import branding from '../../config/branding';

const tipText = 'Don\'t have an account?';

const DontHaveAnAccountTip: React.FC<{}> = () => (
  <div style={{ display: 'flex', marginTop: '30px' }}>
    <span style={{ color: branding.theme.colors.dustyGrey }}>{tipText}</span>
    <Link href="/app/signup" style={{ color: branding.theme.colors.accent }} ml="sm">
      Register here
    </Link>
  </div>
);

export default DontHaveAnAccountTip;
