/* eslint-disable import/order */
/* eslint-disable react/function-component-definition */
import {
  Box,
  Button,
  FormMessage,
  Icon,
  Link,
  Text,
} from '@admin-bro/design-system';
import React, { useCallback, useState, useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import ResetPasswordSchema from '../schemas/reset-password.schema.js';
import SignUpFormGroup from './signup-form-group';
import axios from 'axios';
import styled from 'styled-components';
import validate from '../../utils/validate';

export type ResetPasswordFormData = {
  resetEmail?: string;
}

const emptyForm: ResetPasswordFormData = {
  resetEmail: '',
};

type Properties = keyof ResetPasswordFormData;

type Errors = {
  [key: string]: {
    message: string;
  } | undefined;
}

export const StyledMessage = styled(FormMessage)`
  height: auto;
  min-height: 10px;
`;

const resetPasswordTip = [
  'Enter the email address associated with your account,',
  'and we\'ll send you a link to reset your password.',
];

const ResetPasswordForm: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(emptyForm);
  const [errors, setErrors] = useState<Errors>({});
  const recaptchaRef = useRef(null);

  const onChange = useCallback((param: Properties, value) => {
    setFormData({ ...formData, [param]: value });
  }, [formData]);

  const onSubmit = useCallback(async (event) => {
    event.preventDefault();

    const validationErrors = validate(formData, ResetPasswordSchema);
    if (validationErrors) { return setErrors(validationErrors); }

    const recaptchaToken = recaptchaRef.current.getValue();
    if (!recaptchaToken) {
      setErrors({ ...errors, recaptcha: { message: 'Please complete the reCAPTCHA' } });
      return;
    }

    const data = new FormData();
    Object.entries(formData).forEach(([key, val]) => data.set(key, val));
    data.set('recaptchaToken', recaptchaToken);

    setLoading(true);
    axios.post('/app/reset-password', data).then((response) => {
      window.location = response.data.redirectUrl;
    }).catch((error) => {
      console.log(error);
      if (error.response.status === 401 || error.response.status === 404 || error.response.status === 400) {
        window.location = error.response.data.errors.resetEmail.redirectUrl;
      } else {
        console.log({ error });
      }
      setLoading(false);
    });
  }, [formData, errors]);

  return (
    <Box
      bg="white"
      width={['100%', '100%', '480px']}
      py="xxl"
      px="xxl"
      mt="sm"
      overflow="auto"
    >
      <Box pb="xl">
        {resetPasswordTip.map((tipLine) => (
          <Text key={tipLine} textAlign="center" color="grey60" fontSize="13">{tipLine}</Text>
        ))}
      </Box>
      <form onSubmit={onSubmit}>
        <SignUpFormGroup
          key="email"
          field="resetEmail"
          value={formData.resetEmail}
          errorMessage={errors.resetEmail?.message}
          onChange={onChange}
        />
        <div>
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey="6LezeeopAAAAALssm4Qom5yzTtJZ2ZjlVzgdaCP-" // Replace with your actual site key
          />
        </div>
        <br />
        <Text textAlign="center">
          <Button disabled={loading} variant="primary" type="submit" style={{ borderRadius: '20px' }}>
            {loading ? (<Icon icon="Fade" spin />) : null}
            Continue
          </Button>
        </Text>
      </form>
      <Box py="lg">
        <Link href="/app/login">Return to login</Link>
      </Box>
    </Box>
  );
};

export default ResetPasswordForm;
